import { createActions } from "redux-actions";

export const {
  fetchTverse,
  fetchTverseSuccess,
  fetchTverseBanner,
  fetchTverseBannerSuccess,
} = createActions({
  FETCH_TVERSE: (req) => ({ req }),
  FETCH_TVERSE_SUCCESS: (res) => {
    console.log("FETCH_TVERSE_SUCCESS");
    return { res };
  },
  FETCH_TVERSE_BANNER: (req) => ({ req }),
  FETCH_TVERSE_BANNER_SUCCESS: (res) => ({ res }),
});
