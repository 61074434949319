import { all, call } from "redux-saga/effects";

import { watchTverse } from "./tverse";

export default function* rootSaga() {
  try {
    yield all([watchTverse()]);
  } catch (err) {
    console.log(err);
  }
}
