import { all, put, takeLatest, call, take, select } from "redux-saga/effects";
import * as Actions from "../actions";
import _ from "lodash";
import service from "../service";
import requestManagement from "../service/requestManagement";

const { method_GET, method_POST } = requestManagement;
import axios from "axios";
const headers = {
  "Content-Language": "th",
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY,
};
function successHandler(result) {
  const { status, data: innerData } = result;
  const { code, msg, data } = innerData;
  const dispatch = useDispatch();

  return {
    success: true,
    data: data || innerData,
    status: status,
    code: code,
    msg: msg,
  };
}

export function* getTverse() {
  try {
    const { data } = yield method_GET({
      url: `${service.fetchTverse}`,
      // url: `https://api-dev.brandverse.world/api/v1/t-verse-land`,
    });

    yield put(Actions.fetchTverseSuccess(data.data));
  } catch (error) {
    console.log("getTverse error : ", error);
  }
}

export function* getBanner() {
  try {
    const { data } = yield method_GET({
      url: `${service.fetchTverseBanner}`,
      // url: `https://api-dev.brandverse.world/api/v1/t-verse-banner`,
    });

    yield put(Actions.fetchTverseBannerSuccess(data.data));
  } catch (error) {
    console.error("getBanner error: ", error);
  }
}

export function* watchTverse() {
  console.log("[sagas] watchTverse : ✅");
  yield all([takeLatest(Actions.fetchTverse, getTverse)]);
  yield all([takeLatest(Actions.fetchTverseBanner, getBanner)]);
}
