import axios from "axios";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

const headers = {
  "Content-Language": "th",
  "Content-Type": "application/json",
  Accept: "application/json",
  "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY,
};
const timeout = 20000;

export const method_GET = async ({ url, token }) => {
  try {
    // console.log("url", url);
    // if (token) {
    //     headers['x-streaming-signature'] = token;
    //     headers['x-auth-admin-signature'] = token;
    // }

    const request = await axios({
      url: url,
      method: "GET",
      headers: headers,
      // data: data,
      timeout: timeout,
      dataType: "json",
    });
    // console.log("request", request);

    // console.log("response", request);
    return request;
  } catch (error) {
    console.error("[API] GET Failure", { error });
    if (error instanceof AxiosError) {
      throw errorHandler(error);
    }
    throw error;
  }
};

const method_POST = async ({ url, data, token, upload = false }) => {
  // if (token) headers["token"] = token;
  if (upload) headers["Content-Type"] = "multipart/form-data";

  // console.log("[API] POST : ", data, url);

  const request = axios({
    url: url,
    method: "POST",
    headers: headers,
    data: data,
    timeout: timeout,
    dataType: "json",
  });

  return await request
    .then((response) => {
      // console.log("[API] POST : ", { response });
      return successHandler(response);
    })
    .catch((error) => {
      console.log("[API] POST Failure", { error });
      return errorHandler(error);
    });
};

const method_PUT = async ({ url, data, token }) => {
  if (token) headers["token"] = token;

  console.log("put : ", url, " : ", data);

  const request = axios({
    url: url,
    method: "PUT",
    headers: headers,
    data: data,
    timeout: timeout,
    dataType: "json",
  });

  return await request
    .then((response) => {
      console.log("[API] PUT : ", response);
      return successHandler(response);
    })
    .catch((error) => {
      console.log("[API] PUT Failure", error);
      return error;
    });
};

const method_DELETE = async ({ url, data, token, param }) => {
  if (token) headers["token"] = token;

  const request = axios({
    url: url,
    method: "DELETE",
    headers: headers,
    params: param,
    timeout: timeout,
    dataType: "json",
  });

  return await request
    .then((response) => {
      console.log("[API] DELETE : ", response);
      return successHandler(response);
    })
    .catch((error) => {
      console.log("[API] DELETE Failure", error);
      return error;
    });
};

function successHandler(result) {
  const { status, data: innerData } = result;
  const { code, msg, data } = innerData;

  return {
    success: true,
    data: data || innerData,
    status: status,
    code: code,
    msg: msg,
  };
}

function errorHandler(e) {
  const { response, message } = e;

  console.log("errorHandler : ", { response });

  return {
    error: {
      success: false,
      status: response?.status,
      data: response?.data,
      err: message,
    },
  };
}

export default { method_GET, method_POST, method_PUT, method_DELETE };
