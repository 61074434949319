import { handleActions } from "redux-actions";
import * as Actions from "../actions";

const defaultState = {
  // loading: false,
  tverse: [],
  banner: [],
};

export const reducer = handleActions(
  {
    [Actions.fetchTverseSuccess]: (state, { payload: { res } }) => ({
      ...state,
      tverse: res,
    }),

    [Actions.fetchTverseBannerSuccess]: (state, { payload: { res } }) => ({
      ...state,
      banner: res,
    }),
  },
  defaultState
);
