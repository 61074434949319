import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <Head>

      <title>{`${title || 'Tverse | The best choice of Community and Activity metaverse platform'}`}</title>
      <link rel="icon" href="/favicon.png" />
      <meta name="description" content={desc} />
      <meta name="keyword" content={keyword} />
      <meta property="og:title" content={title} key={keyword} />
    </Head>
  );
};

Meta.defaultProps = {
  title: "Tverse | The best choice of Community and Activity metaverse platform",
  keyword:
    "metaverse,bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "The world's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
};

export default Meta;
