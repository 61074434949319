import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import getConfig from "next/config";

import { reducer as tverseReducer } from "./tverse";

const { publicRuntimeConfig } = getConfig();
const commonPersistConfig = {
  key: "common",
  storage,
};
const assetsPersistConfig = {
  key: "assets",
  storage,
};
const inventoryPersistConfig = {
  key: "inventory",
  storage,
};
const userInfoPersistConfig = {
  key: "userInfo",
  storage,
};

const rootReducer = {
  tverse: tverseReducer,
};

export default rootReducer;
